import Vue from 'vue'
import VueRouter from 'vue-router'
import PilotUsers from './assets/PilotUsers.json';


import App from './App.vue'
import EmployeeApp from './components/EmployeeApp.vue'
import ContractorApp from './components/ContractorApp.vue'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'

import './style/custom.scss'
import './registerServiceWorker'

Vue.use(VueRouter)

// Install BootstrapVue
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)

Vue.config.productionTip = false

// Sets a globally accessible variable to the data in the PilotUsers.json file. 
Vue.prototype.$pilotUsers = PilotUsers;

const router = new VueRouter({
  mode: 'history',
  base: __dirname,
  routes: [
    { path: '/User/:UserId', component: EmployeeApp, props: true },
    { path: '/Contractor', component: ContractorApp, props: true }
  ]
})

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
