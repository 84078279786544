<template>
    <b-container>
        <b-dropdown right class="pull-right float-right" style="top: -36px;" variant="transparent" v-if="swsupported">
            <template #button-content class="bg-transparent">
                <b-icon icon="gear-fill" variant="dark" font-scale="1.6" class="text-center"></b-icon>
            </template>
            <b-dropdown-item href="#" @click="toggleSW()"><b-icon icon="check" variant="success" font-scale="1" v-if="swenabled"></b-icon>Make Available Offline</b-dropdown-item>
        </b-dropdown>
    </b-container>
</template>

<script>

    import { register } from 'register-service-worker'

export default {
    name: 'SWWidget',
    data() {
      return {
        swsupported: false,
        swenabled: false,
        needsrefresh: false,
      }
    },
    mounted() {
        var component = this;
        component.swsupported = (!!navigator.serviceWorker);

        navigator.serviceWorker.getRegistration(`${process.env.BASE_URL}sw.js`).then(function (registration) {
            component.swenabled = !!registration;

            if (component.swenabled) {
                component.registerSW();
            }
        });
    },
    methods: {
        toggleSW() {
            var component = this;
            if (component.swenabled) {
                navigator.serviceWorker.getRegistration(`${process.env.BASE_URL}sw.js`).then(function () {
                    navigator.serviceWorker.ready.then(function (registration) {
                        registration.unregister().then(function () { location.reload(); });
                    });                       
                });
            } else {
                component.registerSW();
                component.needsrefresh = true;
            }
        },
        registerSW() {
            var component = this;
            var refreshing = false;

            register(`${process.env.BASE_URL}sw.js`, {
                ready() {
                    console.log(
                        'App is being served from cache by a service worker.\n' +
                        'For more details, visit https://goo.gl/AFskqB'
                    )
                    component.swenabled = true;
                    if (component.needsrefresh && !refreshing) {
                        window.location.reload();
                        refreshing = true;
                    }
                },
                registered() {
                    console.log('Service worker has been registered.');
                },
                cached() {
                    console.log('Content has been cached for offline use.')
                },
                updatefound() {
                    console.log('New content is downloading.')
                },
                updated(registration) {
                    console.log('New content is available; please refresh.');
                    registration.waiting.postMessage({ type: "SKIP_WAITING" });
                },
                offline() {
                    console.log('No internet connection found. App is running in offline mode.')
                },
                error(error) {
                    console.error('Error during service worker registration:', error)
                }
            });

            navigator.serviceWorker.addEventListener("controllerchange", function () {
                if (refreshing) return;
                window.location.reload();
                refreshing = true;
            });
        }
    }
}

</script>