<template>
<div>
    <b-row>
      <b-col lg="6" class="my-2">
        <b-form-group
          label-cols-sm="12"
          label-align-sm="left"
          label-size="sm"
          label-for="filterInput"
          class="mb-0"
        >
          <b-input-group size="sm">
            <b-form-input
              v-model="dataTableFilter"
              type="search"
              id="filterInput"
              placeholder="Type to Search"
            ></b-form-input>
            <b-input-group-append>
              <b-button :disabled="!dataTableFilter" @click="dataTableFilter = ''">Clear</b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col lg="6" class="my-2 d-flex justify-content-end align-items-center" >
            <b-form-checkbox :id="'checkbox-group-' + this.uniqueTableId" v-model="dataTableBoxSelected" value="Y" unchecked-value="N" v-if="dropdownFilterSelected === 'All'">
              Qualified Only
          </b-form-checkbox>
          <b-form-checkbox :id="'checkbox-group-' + this.uniqueTableId" v-model="dataTableBoxRole" value="Y" unchecked-value="N" v-else>
              Qualified Only
          </b-form-checkbox>
      </b-col>
      
    </b-row>
    <b-row class="pb-3 mb-3 border-bottom">
      <b-col>
        <b-table 
          show-empty
          small
          :items="dataTableItems"
          :fields="dataTableFields"
          :filter="dataTableFilter"
          :filterIncludedFields="dataTableFilterOn"
          striped>
          <template v-slot:cell(isTaskQualified)="data">
            <div v-if="data.item.isTaskQualified === 'Y' && data.item.isTaskExpiringWithin90Days == false">
                <b-icon icon="check-circle" font-scale="2" variant="success" bold></b-icon>
            </div>
            <div v-else-if="data.item.isTaskQualified === 'Y' && data.item.isTaskExpiringWithin90Days == true">
                <b-icon icon="exclamation-triangle" font-scale="2" variant="warning" bold></b-icon>
            </div>
            <div v-else>
                <b-icon icon="x-circle" font-scale="2" variant="danger"></b-icon>
            </div>
          </template>
          <!-- <template v-slot:cell(actions)="row">
            <b-button size="sm" @click="info(row.item, row.index, $event.target)" class="mr-1">
              More Info
            </b-button>
          </template> -->

          <template v-slot:row-details="row">
                <b-card>
                  <ul>
                    <li v-for="(value, key) in row.item" :key="key">{{ key }}: {{ value }}</li>
                  </ul>
                </b-card>
            </template>
        </b-table>
        <b-modal size="lg" :id="dataTableInfoModal.id" :title="dataTableInfoModal.title" ok-only @hide="resetInfoModal">
            <b-row>
                <b-col sm="9"><b>{{ dataTableInfoModal.content.taskName }}</b></b-col>
                <b-col sm="3" class="text-right">
                    <div class="pr-2" v-if="dataTableInfoModal.content.isTaskQualified === 'Y' && dataTableInfoModal.content.isTaskExpiringWithin90Days == false">
                        <b-icon icon="check-circle" font-scale="2" variant="success" bold></b-icon>
                    </div>
                    <div class="pr-2" v-else-if="dataTableInfoModal.content.isTaskQualified === 'Y' && dataTableInfoModal.content.isTaskExpiringWithin90Days == true">
                        <b-icon icon="exclamation-triangle" font-scale="2" variant="warning" bold></b-icon>
                    </div>
                    <div class="pr-2" v-else>
                        <b-icon icon="x-circle" font-scale="2" variant="danger"></b-icon>
                    </div>
                </b-col>
            </b-row>
            <b-row>
                <b-col>Taken: {{ formatDate(dataTableInfoModal.content.dateKnowledgeTaken) }}</b-col>
                <!-- VA uses this instead of  -->
                <!-- <b-col>Expires: {{ formatDate(dataTableInfoModal.content.dateKnowledgeCertExpired) }}</b-col> -->
                <b-col>Expires: {{ formatDate(dataTableInfoModal.content.dateSkillCertExpired) }}</b-col>
            </b-row>
            <!-- VA uses this -->
            <!--
            <div v-for="skill in dataTableInfoModal.content.skills" :key="skill.skillCode">
              <div v-if="skill.skillName !== null">
                <b-row class="bg-medium pb-2 pt-2 mb-2 mt-4">
                    <b-col><b>{{ skill.skillCode }}</b></b-col>
                </b-row>
                <b-row>
                    <b-col>{{ skill.skillName }}</b-col>
                </b-row>
                <b-row>
                    <b-col>Taken: {{ formatDate(skill.dateSkillTaken) }}</b-col>
                    <b-col>Expires: {{ formatDate(skill.dateSkillCertExpired) }}</b-col>
                </b-row>
                </div>
            </div> 
            -->
            <!-- End VA uses this -->
        </b-modal>
      </b-col>
    </b-row>
    </div>
</template>

<script>
  import moment from 'moment'

  export default {
    name: 'DataTable',
    props: {
        certInfo: Array,
        dropdownFilterSelectedValue: String,
        uniqueTableId: String
    },
    data() {
      return {
        dataTableFields: [
        'taskName', 
        {
            key: 'dateKnowledgeTaken',
            label: 'Date Taken',
            formatter: 'formatDate',
            class: 'd-sm-none d-md-table-cell'
        }, 
        // VA uses dateKnowledgeCertExpired
        {
            key: 'dateSkillCertExpired',
            label: 'Expires',
            formatter: 'formatDate'
        },  
        {
            key: 'isTaskQualified',
            label: ''
        },
        { 
            key: 'actions', 
            label: '' 
        }
        ],
        dataTableRawItems: this.certInfo,
        dataTableFilter: null,
        dataTableFilterOn: [],
        dataTableInfoModal: {
          id: 'info-modal' + this.uniqueTableId,
          title: '',
          content: '',
        },

        dataTableBoxSelected: "Y",
        dataTableBoxRole: "N",
      }
    },
    computed: {
        dataTableItems() {
         if(this.dropdownFilterSelected !== 'All' && this.dropdownFilterSelected !== 'Roles') {
            if(this.dataTableBoxRole == 'Y') {
               return this.dataTableRawItems.map(dataTableRawItem => dataTableRawItem)
                          .filter(dataTableRawItem => dataTableRawItem.isTaskQualified == this.dataTableBoxRole)
                          .filter(dataTableRawItem => dataTableRawItem.seriesId == this.dropdownFilterSelected);
             } else {
               return this.dataTableRawItems.map(dataTableRawItem => dataTableRawItem)
                          .filter(dataTableRawItem => dataTableRawItem.seriesId == this.dropdownFilterSelected);
             }
         } else if (this.dropdownFilterSelected === 'Roles')
         {
            if(this.dataTableBoxRole == 'Y') {
             return this.dataTableRawItems.map(dataTableRawItem => dataTableRawItem)
                        .filter(dataTableRawItem => dataTableRawItem.isTaskQualified == this.dataTableBoxRole);
           } else {
             return this.dataTableRawItems.map(dataTableRawItem => dataTableRawItem)
           }
         } 
         else {
           if(this.dataTableBoxSelected == 'Y') {
             return this.dataTableRawItems.map(dataTableRawItem => dataTableRawItem)
                        .filter(dataTableRawItem => dataTableRawItem.isTaskQualified == this.dataTableBoxSelected);
           } else {
             return this.dataTableRawItems.map(dataTableRawItem => dataTableRawItem)
           }

         }
        },
         dropdownFilterSelected() {
            return this.dropdownFilterSelectedValue
        }
    },
    mounted() {
      // Set the initial number of items
       this.totalRows = this.dataTableItems.length
    },
    methods: {
      info(item, index, button) {
        this.dataTableInfoModal.title = item.taskCode
        this.dataTableInfoModal.content = item
        this.$root.$emit('bv::show::modal', this.dataTableInfoModal.id, button)
      },
      resetInfoModal() {
        this.dataTableInfoModal.title = ''
        this.dataTableInfoModal.content = ''
      },
      formatDate(value) { 
          if(moment(value).isValid()) {
              return moment(value).format('MM/DD/YYYY')
          }
      },
      consoleLog(toLog) {
        console.log(toLog)
      }
    },
  }
</script>