<template>
  <div v-if="totalRows > 0">
    <b-row v-if="title">
      <b-col>
        <h5>{{ title }}</h5>
        <div v-if="description" v-html="description"></div>
      </b-col>
    </b-row>

    <b-row class="pb-3 mb-3 border-bottom">
      <b-col>
        <b-table
          show-empty
          small
          :items="dataTableItems"
          :fields="dataTableFields"
          :filter="dataTableFilter"
          :filterIncludedFields="dataTableFilterOn"
          striped
        >
          <template v-slot:cell(sopName)="data">
            <template v-if="data.item.desktopUrl">
              <b-link :href="data.item.desktopUrl" target="_blank">
                {{ data.item.sopName }}
              </b-link>
            </template>
            <template v-else>
              {{ data.item.sopName }}
            </template>
          </template>
        </b-table>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  name: "SopDataTable",
  props: {
    sopData: Array,
    uniqueTableId: String,
    title: String,
    description: String,
  },
  data() {
    return {
      dataTableFields: [
        {
          key: "sopStatusDescription",
          label: "Status",
          thStyle: "width: 120px"
        },
        {
          key: "sopReferenceId",
          label: "Reference No.",
          thStyle: "width: 180px"
        },
        {
          key: "sopName",
          label: "SOP Name",
        }
      ],
      dataTableRawItems: this.sopData,
      dataTableFilter: null,
      dataTableFilterOn: [],
    };
  },
  computed: {
    dataTableItems() {
      return this.sopData.map((dataTableRawItem) => dataTableRawItem);
    },
    totalRows() {
      return this.dataTableItems.length;
    }
  },
  methods: {
    consoleLog(toLog) {
      console.log(toLog);
    },
  },
};
</script>