<template>
  <div v-if="errorMessage">
    <b-row v-if="title">
      <b-col>
        <h3 class="pt-3">{{ title }}</h3>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-alert show variant="danger">
          <b-row align-v="center">
            <b-col cols="6" align-self="center">
              <b>{{ errorMessage }}</b>
            </b-col>
            <b-col v-if="isOnline === false" cols="6" class="clearfix">
              <b-button
                variant="light"
                class="float-right"
                :href="sopUrl"
                target="_blank"
              >
                Click Here to View SOPs in Comply365
              </b-button>
            </b-col>
          </b-row>
        </b-alert>
      </b-col>
    </b-row>
  </div>
  <div v-else>
    <b-row v-if="title">
      <b-col>
        <h3 class="pt-3">{{ title }}</h3>
        <div v-if="description" v-html="description"></div>
      </b-col>
    </b-row>
    <b-row>
      <b-col  sm="12" lg="6" class="my-2">
        <b-form-group
          label-cols-sm="12"
          label-align-sm="left"
          label-size="sm"
          label-for="filterInput"
          class="mb-0"
        >
          <b-input-group size="sm">
            <b-form-input
              v-model="dataTableFilter"
              type="search"
              id="filterInput"
              placeholder="Type to Search"
            ></b-form-input>
            <b-input-group-append>
              <b-button
                :disabled="!dataTableFilter"
                @click="dataTableFilter = ''"
                >Clear</b-button
              >
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col sm="12" lg="6" class="text-center my-3">
        <b-row>
          <b-col sm class="p-0">
            <b-icon icon="check-circle" font-scale="1.25" variant="success" bold class="align-middle"></b-icon> Qualified
          </b-col>
          <b-col sm="5" class="p-0">
            <b-icon icon="exclamation-triangle" font-scale="1.25" variant="warning" bold class="align-middle"></b-icon> Due Within 90 Days
          </b-col>   
          <b-col sm class="p-0">
            <b-icon icon="x-circle" font-scale="1.25" variant="danger" bold class="align-middle"></b-icon> Not Qualified
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <b-row class="pb-3 mb-3 border-bottom">
      <b-col>
        <b-table
          show-empty
          small
          :items="dataTableItems"
          :fields="dataTableFields"
          :filter="dataTableFilter"
          :filterIncludedFields="dataTableFilterOn"
          striped
        >
          <template v-slot:cell(isTaskQualified)="data">
            <div v-if="data.item.isTaskQualified === 'Y' && data.item.isTaskExpiringWithin90Days == false">
              <b-icon
                icon="check-circle"
                font-scale="2"
                variant="success"
                bold
              ></b-icon>
            </div>
            <div v-else-if="data.item.isTaskQualified === 'Y' && data.item.isTaskExpiringWithin90Days == true">
              <b-icon
                icon="exclamation-triangle"
                font-scale="2"
                variant="warning"
                bold
              ></b-icon>
            </div>
            <div v-else>
              <b-icon icon="x-circle" font-scale="2" variant="danger"></b-icon>
            </div>
          </template>
          <!-- <template v-slot:cell(actions)="row">
                      <b-button size="sm" @click="info(row.item, row.index, $event.target)" class="mr-1">
                        More Info
                      </b-button>
                    </template> -->

          <template v-slot:row-details="row">
            <b-card>
              <ul>
                <li v-for="(value, key) in row.item" :key="key">
                  {{ key }}: {{ value }}
                </li>
              </ul>
            </b-card>
          </template>
        </b-table>
        <b-modal
          size="lg"
          :id="dataTableInfoModal.id"
          :title="dataTableInfoModal.title"
          ok-only
          @hide="resetInfoModal"
        >
          <b-row>
            <b-col sm="9">
              <b>{{ dataTableInfoModal.content.taskName }}</b>
            </b-col>
            <b-col sm="3" class="text-right">
              <div
                class="pr-2"
                v-if="dataTableInfoModal.content.isTaskQualified === 'Y' && dataTableInfoModal.content.isTaskExpiringWithin90Days == false"
              >
                <b-icon
                  icon="check-circle"
                  font-scale="2"
                  variant="success"
                  bold
                ></b-icon>
              </div>
              <div
                class="pr-2"
                v-else-if="dataTableInfoModal.content.isTaskQualified === 'Y' && dataTableInfoModal.content.isTaskExpiringWithin90Days == true"
              >
                <b-icon
                  icon="exclamation-triangle"
                  font-scale="2"
                  variant="warning"
                  bold
                ></b-icon>
              </div>
              <div class="pr-2" v-else>
                <b-icon
                  icon="x-circle"
                  font-scale="2"
                  variant="danger"
                ></b-icon>
              </div>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              Taken:
              {{ formatDate(dataTableInfoModal.content.dateKnowledgeTaken) }}
            </b-col>
            <!-- VA uses this instead of  -->
            <!-- <b-col>Expires: {{ formatDate(dataTableInfoModal.content.dateKnowledgeCertExpired) }}</b-col> -->
            <b-col>
              Expires:
              {{ formatDate(dataTableInfoModal.content.dateSkillCertExpired) }}
            </b-col>
          </b-row>
          <!-- VA uses this -->
          <!--
                      <div v-for="skill in dataTableInfoModal.content.skills" :key="skill.skillCode">
                        <div v-if="skill.skillName !== null">
                          <b-row class="bg-medium pb-2 pt-2 mb-2 mt-4">
                              <b-col><b>{{ skill.skillCode }}</b></b-col>
                          </b-row>
                          <b-row>
                              <b-col>{{ skill.skillName }}</b-col>
                          </b-row>
                          <b-row>
                              <b-col>Taken: {{ formatDate(skill.dateSkillTaken) }}</b-col>
                              <b-col>Expires: {{ formatDate(skill.dateSkillCertExpired) }}</b-col>
                          </b-row>
                          </div>
                      </div>
                      -->
          <!-- End VA uses this -->
        </b-modal>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import moment from "moment";

export default {
  name: "JobTypeDataTable",
  props: {
    jobTypeInfo: Array,
    errorMessage: String,
    dropdownFilterSelectedValue: String,
    uniqueTableId: String,
    title: String,
    description: String,
    isOnline: Boolean,
  },
  data() {
    return {
      dataTableFields: [
        "taskName",
        {
          key: "dateKnowledgeTaken",
          label: "Date Taken",
          formatter: "formatDate",
          class: "d-sm-none d-md-table-cell",
        },
        // VA uses dateKnowledgeCertExpired
        {
          key: "dateSkillCertExpired",
          label: "Expires",
          formatter: "formatDate",
        },
        {
          key: "isTaskQualified",
          label: "",
        },
        {
          key: "actions",
          label: "",
        },
      ],
      dataTableFilter: null,
      dataTableFilterOn: [],
      dataTableInfoModal: {
        id: "info-modal" + this.uniqueTableId,
        title: "",
        content: "",
      },

      dataTableBoxSelected: "Y",
      dataTableBoxRole: "N",
      sopUrl: process.env.VUE_APP_SOP_URL,
    };
  },
  computed: {
    dataTableItems() {
      return this.jobTypeInfo.map((dataTableRawItem) => dataTableRawItem);
    },
    dropdownFilterSelected() {
      return this.dropdownFilterSelectedValue;
    },
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.dataTableItems.length;
  },
  updated() {
    this.totalRows = this.dataTableItems.length;
  },
  methods: {
    info(item, index, button) {
      this.dataTableInfoModal.title = item.taskCode;
      this.dataTableInfoModal.content = item;
      this.$root.$emit("bv::show::modal", this.dataTableInfoModal.id, button);
    },
    resetInfoModal() {
      this.dataTableInfoModal.title = "";
      this.dataTableInfoModal.content = "";
    },
    formatDate(value) {
      if (moment(value).isValid()) {
        return moment(value).format("MM/DD/YYYY");
      } else {
        return "";
      }
    },
    consoleLog(toLog) {
      console.log(toLog);
    },
  },
};
</script>