<template>
    <div id="app">
        <div class="text-center sticky-top pt-2 pb-2 mb-5 bg-light border-bottom">
            <b-img src="./assets/NiSource-Logo.png" alt="NiSource Logo"></b-img>
            <SWWidget></SWWidget>
        </div>
        <router-view>

        </router-view>
    </div>
</template>

<script>
    import SWWidget from "./components/SWWidget.vue";

    export default {
        name: "App",
        components: {
            SWWidget,
        },
        bootstrapVue: {
            bootstrapCSS: true,
            bootstrapVueCSS: false,
        },
        mounted() {},
        methods: {
            consoleLog(toLog) {
                console.log(toLog);
            },
        },
    };
</script>