<template>
  <b-container>
    <b-row>
      <b-col align-self="center">
        <b-alert show variant="danger" class="text-center">
          <b>{{ msg }}</b>
        </b-alert>
      </b-col>
    </b-row>
    <b-row v-if="refreshData && typeof(refreshData) === 'function'">
      <b-col class="text-center">
        <b-button @click="refreshData" variant="light"><b-icon icon="arrow-repeat" variant="success" font-scale="1.3" rotate="45" class="text-center"></b-icon> Reload</b-button>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>

export default {
  name: 'Error',
  props: {
      msg: String,
      refreshData: Function
  }
}

</script>