import { HTTP } from '@/lib/http-common';


export default {
  fetchEmployeeInfo(userId) {
    return HTTP
      .get('/employee', {
        params: {
          UserId: userId
        }
      })
      .then(response => {
        return response.data
      })
      .catch(error => {
        console.log(error)
        if (error.response && error.response.data) {
          this.errorMessage = error.response.data
        } else {
          this.errorMessage = "Error loading Employee information, please try again later."
        }
        return this.errorMessage;
      });
  },
  fetchTranscriptGroupedByTasks(userId, timeZoneOffset) {
    return HTTP
      .get('/certinfo', {
        params: {
          UserId: userId,
          TimeZoneOffset: timeZoneOffset
        }
      })
      .then(response => {
        return response.data
      })
      .catch(error => {
        console.log(error)
        if (error.response && error.response.data) {
          this.errorMessage = error.response.data
        } else {
          this.errorMessage = "Error loading Qualification data, please try again later."
        }
        return this.errorMessage;
      })
  },
  fetchJobTypeList() {
    return HTTP
      .get('/jobType/list')
      .then(response => {
        return response.data
      })
      .catch(error => {
        console.log(error)
        if (error.response && error.response.data) {
          this.errorMessage = error.response.data
        } else {
          this.errorMessage = "Error loading Job Type data, please try again later."
        }
        return this.errorMessage;
      })
  },
  fetchAllSOPList() {
    return HTTP
      .get('/sop/list')
      .then(response => {
        return response.data
      })
      .catch(error => {
        console.log(error)
        if (error.response && error.response.data) {
          this.errorMessage = error.response.data
        } else {
          this.errorMessage = "Error loading SOP data, please try again later."
        }
        return this.errorMessage;
      })
  },
  fetchAllSOPJobTypeList() {
    return HTTP
      .get('/sopJobType/list')
      .then(response => {
        return response.data
      })
      .catch(error => {
        console.log(error)
        if (error.response && error.response.data) {
          this.errorMessage = error.response.data
        } else {
          this.errorMessage = "Error loading Sop Job Type data, please try again later."
        }
        return this.errorMessage;
      })
  },
  fetchJobTypeMappings(userId) {
    return HTTP
      .get('/jobType/mappings', {
        params: {
          userId: userId
        }
      })
      .then(response => {
        return response.data
      }) 
      .catch(error => {
        console.log(error)
        if (error.response && error.response.data) {
          this.errorMessage = error.response.data
        } else {
          this.errorMessage = "Error loading Job Type Mapping data, please try again later."
        }
        return this.errorMessage;
      })
  }, 
}