<template>
    <b-container>
        <div class="d-flex justify-content-center mb-3" v-if="loading">
            <b-spinner style="width: 3rem; height: 3rem"
                        label="Large Spinner"></b-spinner>
        </div>
        <div v-else>
            <div v-if="errored">
                <Error :msg="errorMessage" :refreshData="refreshAllData" />
            </div>
            <div v-else-if="employeeInfo.userIsEnabled === 'N'">
                <Error msg="You have scanned a user that is inactive."
                        :refreshData="refreshAllData" />
            </div>
            <div v-else>
                <EmployeeInfo :employeeInfo="employeeInfo" />
                <CertData :groupedByTasks="certData.groupedCertTasks"
                            :groupedByRoles="certData.groupedRoleTasks"
                            :jobTypes="certData.jobTypes"
                            :asOfDate="certData.asOfDate"
                            :refreshCertData="refreshCertData" 
                            :employeeState="employeeInfo.state"/>
            </div>
        </div>
    </b-container>
</template>

<script>
    import Error from "./Error.vue";
    import EmployeeInfo from "./EmployeeInfo.vue";
    import CertData from "./CertData.vue";
    import ApiService from "@/lib/api-service";

    export default {
        name: "EmployeeApp",
        data() {
            return {
                errored: false,
                errorMessage: "We are experiencing technical difficulties.",
                loading: true,
                employeeInfo: null,
                certData: {
                    groupedCertTasks: null,
                    groupedRoleTasks: null,
                    asOfDate: null,
                },
            };
        },
        components: {
            Error,
            EmployeeInfo,
            CertData,
        },
        bootstrapVue: {
            bootstrapCSS: true,
            bootstrapVueCSS: false,
        },
        mounted() {
            Promise.all([this.getEmployeeInfo(), this.getTranscriptGroupedByTasks()])
                .then()
                .finally(() => (this.loading = false));
        },
        methods: {
            getEmployeeInfo() {
                return ApiService.fetchEmployeeInfo(this.$route.params.UserId).then(
                    (response) => {
                        if (typeof response === "string") {
                            this.errored = true;
                            this.errorMessage = response;
                        } else {
                            this.employeeInfo = response;
                        }
                    }
                );
            },
            getTranscriptGroupedByTasks() {
                return ApiService.fetchTranscriptGroupedByTasks(
                    this.$route.params.UserId,
                    new Date().getTimezoneOffset()
                ).then((response) => {
                    if (typeof response === "string") {
                        this.errored = true;
                        this.errorMessage = response;
                    } else {
                        (this.certData.groupedCertTasks = response.groupedCertTasks),
                        (this.certData.groupedRoleTasks = response.groupedRoleTasks),
                        (this.certData.asOfDate = response.asOfDate);
                    }
                });
            },
            consoleLog(toLog) {
                console.log(toLog);
            },
            refreshCertData() {
                this.errored = false;
                this.errorMessage = "";
                this.loading = true;
                this.getTranscriptGroupedByTasks().finally(() => (this.loading = false));
            },
            refreshAllData() {
                this.errored = false;
                this.errorMessage = "";
                this.loading = true;
                Promise.all([
                    this.getEmployeeInfo(),
                    this.getTranscriptGroupedByTasks(),
                ]).finally(() => (this.loading = false));
            },
        },
    };
</script>