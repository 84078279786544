<template>
  <b-container>
    <b-row class="pb-3 mb-3 border-bottom">
      <b-col cols="12" align-self="center">
        <h2 class="mb-0">
          {{ employeeInfo.companyName}}
        </h2>
      </b-col>
    </b-row>

    <b-row class="pb-3 mb-3 border-bottom">
      <b-col cols="4">
          <b-img thumbnail fluid :alt="fullName" :src="employeePhoto" @error="onImgError"></b-img>
        </b-col>
      <b-col cols="8">
        <b>{{ fullName }}</b><br />
        {{ employeeInfo.userName }}<br />
        <p><a :href="`mailto:${employeeInfo.userEmail}`">{{employeeInfo.userEmail}}</a></p>
        <p><a :href="`tel:${employeeInfo.phone}`">{{employeeInfo.phone}}</a></p>
        <p><i>{{ employeeInfo.jobTitle }} </i></p>
        <p>{{ employeeInfo.streetAddress }}<br />{{ employeeInfo.city }}, {{ employeeInfo.state }} {{ employeeInfo.zip }}</p>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
  import defaultImg from '@/assets/default-image.png'
  export default {
    name: 'EmployeeInfo',
    props: {
      employeeInfo: Object
    },
    data: function () {
      return {
        imgError: false
      }
    },
    methods: {
      onImgError(e) {
        e.target.src = defaultImg
      }
    },
    computed: {
      fullName: function() {
        if(this.employeeInfo.middleName === "" || this.employeeInfo.middleName === null) {
          return this.employeeInfo.firstName + ' ' + this.employeeInfo.lastName
        }
        return this.employeeInfo.firstName + ' ' + this.employeeInfo.middleName + ' '+ this.employeeInfo.lastName
      },
      employeePhoto: function() {
        return '/employee-images/' + this.$route.params.UserId + '.jpg'
      }
    }
  }
</script>