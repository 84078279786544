<template>
  <div v-if="totalRows > 0">
    <b-row v-if="title">
      <b-col>
        <h5>{{ title }}</h5>        
        <div v-if="description" v-html="description"></div>
      </b-col>
    </b-row>

    <b-row class="pb-3 mb-3 border-bottom">
      <b-col>
        <b-table
          show-empty
          small
          :items="dataTableItems"
          :fields="dataTableFields"
          :filter="dataTableFilter"
          :filterIncludedFields="dataTableFilterOn"
          striped
          stacked="sm"
          class="sopTable"
        >

          <!-- Hyperlinked SOP Name Column  -->
          <template v-slot:cell(sopName)="data">
            <template v-if="data.item.pilotDesktopUrl !== null && data.item.pilotDesktopUrl.trim() != ''">
              <b-link :href="data.item.pilotDesktopUrl" target="_blank">
                {{ data.item.sopName }}
              </b-link>
            </template>
            <template v-else-if="data.item.desktopUrl !== null && data.item.desktopUrl.trim() !== ''">
              <b-link :href="data.item.desktopUrl" target="_blank">
                {{ data.item.sopName }}
              </b-link>
            </template>
            <template v-else>
              {{ data.item.sopName }}
            </template>
            <template v-if="data.item.sopAdditionalDetails != null && !(/^\s+$/.test(data.item.sopAdditionalDetails))">
              <div>
                {{ data.item.sopAdditionalDetails }}
              </div>
            </template>
          </template>

          <!-- SOP Completions Buttons -->
          <template v-slot:cell(links)="data">
            <template v-if="data.item.desktopUrl !== null || data.item.pilotDesktopUrl !== null">
              <b-button-group size="sm">
                <template v-if="(data.item.sopStatusDescription === 'Mandatory' || data.item.sopStatusDescription === 'CKY Pilot') && /\S/.test(data.item.sopReferenceId)">
                  <b-button target="_blank" :href="createDevonWayLink(devonWayDesktopLink, data.item.sopReferenceId.replace(/[^\w\(\)]/g,''))" variant="outline-primary">
                    SOP Completion (Web Browser)
                  </b-button>
                  <b-button target="_blank" :href="createDevonWayLink(devonWayMobileLink, `${data.item.sopReferenceId} ${data.item.sopName}`)" variant="outline-primary">
                    SOP Completion (Mobile App)
                  </b-button>
                </template>
              </b-button-group>
            </template>
            <template v-else>
            </template>
          </template>
          
        </b-table>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  name: "SopDataTablePilot",
  props: {
    sopData: Array,
    uniqueTableId: String,
    title: String,
    description: String,
  },
  data() {
    return {
      dataTableFields: [
        {
          key: "sopReferenceId",
          label: "Reference No.",
          thStyle: "width: 175px"
        },
        {
          key: "sopName",
          label: "SOP Name",
        },
        {
          key: "links",
          label: "Links",
          thStyle: "min-width: 200px; width: 27%;"
        }
      ],
      dataTableRawItems: this.sopData,
      dataTableFilter: null,
      dataTableFilterOn: [],
      totalRows: 0,
      devonWayDesktopLink: process.env.VUE_APP_DEVONWAY_DESKTOP_URL,
      devonWayMobileLink: process.env.VUE_APP_DEVONWAY_MOBILE_URL,
    };
  },
  computed: {
    dataTableItems() {
      return this.sopData.map((dataTableRawItem) => dataTableRawItem);
    },
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.dataTableItems.length;
  },
  methods: {
    consoleLog(toLog) {
      console.log(toLog);
    },
    createDevonWayLink(baseString, sopInfo) {
      let link = baseString;
      link = link.replace('{replacedString}', encodeURIComponent(sopInfo));
      return link;
    }
  },
};
</script>

<style>
  @media only screen and (max-width: 767px) {
    table { 
      font-size: 14px; 
    }

    .btn-sm, .btn-group-sm > a.btn {
      font-size: 12px;
      padding: 5px;
    }

    .sopTable.table.b-table.b-table-stacked-sm > tbody > tr > [data-label]::before {
      width: 35%;
    }
  }
</style>