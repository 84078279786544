<template>
    <div>
        <loading :active.sync="isLoading"
            :loader="loaderType"
            :color="loaderColor"
            :background-color="bgroundColor"
            :is-full-page="fullPage"></loading>
        <b-container>
            <div v-if="errored">
                <Error :msg="errorMessage" :refreshData="refreshAllData" />
            </div>
            <div v-else>
                <b-container>
                    <h1 class="text-center">Job Type - Standard Operation Procedure Lookup</h1>
                    <div class="my-2 autocomplete" role="tablist">
                        <b-card>
                            <b-form-group label-cols-sm="12"
                                            label-align-sm="left"
                                            label-size="sm"
                                            label-for="filterInput"
                                            class="mb-0">
                                <b-input-group size="sm">
                                    <b-form-input v-model="search"
                                                    type="search"
                                                    id="filterInput"
                                                    placeholder="Start Typing to Search"
                                                    @input="onChange"
                                                    @keydown.down="onArrowDown"
                                                    @keydown.up="onArrowUp"
                                                    @keydown.enter="onEnter" />

                                    <b-input-group-append>
                                        <b-button :disabled="!search" @click="clearData">Clear</b-button>
                                    </b-input-group-append>
                                </b-input-group>
                            </b-form-group>

                            <ul v-show="isOpen" class="autocomplete-results">
                                <li v-for="(result, i) in results"
                                    :key="i"
                                    @click="setResult(result)"
                                    class="autocomplete-result"
                                    :class="{ 'is-active': i === arrowCounter }"
                                    v-on:mouseover="updateArrow(i)">
                                    {{ result.sName }}
                                </li>
                            </ul>
                            <template v-if="isOnline">
                                <sopComponent :title="SOP_TITLE"
                                                :description="SOP_DESCRIPTION"
                                                :sopDataObject="sopResponseData"
                                                :errorMessage="sopErrorMsg"
                                                :jobName="search"
                                                :employeeState="employeeState"
                                                v-show="dataReady" />
                            </template>
                        </b-card>
                    </div>
                </b-container>
            </div>
        </b-container>
    </div>
</template>

<script>
    import Error from "./Error.vue";
    import sopComponent from "./SopComponent";
    import Loading from "vue-loading-overlay";
    import ApiService from "@/lib/api-service";
    import "vue-loading-overlay/dist/vue-loading.css";
    import { SOP } from "../utils/locale";

    export default {
        name: "ContractorApp",
        data() {
            return {
                errored: false,
                errorMessage: "We are experiencing technical difficulties.",
                search: "",
                results: [],
                sItems: [],
                isOpen: false,
                arrowCounter: -1,
                dataReady: false,
                isLoading: false,
                fullPage: true,
                loaderType: "bars",
                loaderColor: "rgb(247, 151, 41)",
                bgroundColor: "rgb(0,0,0)",
                sopResponseData: {},
                sopErrorMsg: "",
                isOnline: true,
                jobTypeList: [],
                sopList: [],
                sopJobTypeList: [],
                employeeState: "All"
            };
        },
        components: {
            Error,
            sopComponent,
            Loading
        },
        created() {
            this.SOP_TITLE = SOP.TITLE;
            this.SOP_DESCRIPTION = SOP.DESCRIPTION;
        },
        mounted() {
            Promise.all([this.fetchJobTypeList(), this.fetchAllSOPList(), this.fetchAllSOPJobTypeList()])
                .then()
                .finally(() => (this.loading = false));
        },
        destroyed() {
            document.removeEventListener("click", this.closeAutocomplete);
        },
        bootstrapVue: {
            bootstrapCSS: true,
            bootstrapVueCSS: false,
        },
        methods: {
            fetchJobTypeList() {
                return ApiService.fetchJobTypeList()
                    .then((response) => {
                        if (typeof response === "string") {
                            this.errored = true;
                            this.errorMessage = response;
                        } else {
                            this.jobTypeList = response;

                            var jtItems = this.jobTypeList;
                            var tempItems = [];

                            jtItems.forEach(function (value) {
                                tempItems.push({
                                    sId: value.id,
                                    sName: value.jobTypeCode + " - " + value.jobTypeDesc,
                                });
                            });

                            document.addEventListener("click", this.closeAutocomplete);
                            this.sItems = tempItems;
                        }
                    });
            },
            fetchAllSOPList() {
                return ApiService.fetchAllSOPList()
                    .then((response) => {
                        if (typeof response === "string") {
                            this.errored = true;
                            this.errorMessage = response;
                        } else {
                            this.sopList = response;
                        }
                    });
            },
            fetchAllSOPJobTypeList() {
                return ApiService.fetchAllSOPJobTypeList()
                    .then((response) => {
                        if (typeof response === "string") {
                            this.errored = true;
                            this.errorMessage = response;
                        } else {
                            this.sopJobTypeList = response;
                        }
                    });
            },
            onChange() {
                if (this.search == "") {
                    this.isOpen = false;
                } else {
                    this.isOpen = true;
                    this.filterResults();
                }
            },
            filterResults() {
                const searchCon = this.search.toLowerCase().trim();
                if (!searchCon) {
                    this.results = this.sItems;
                }
                this.results = this.sItems.filter(
                    (s) => s.sName.toLowerCase().indexOf(searchCon) > -1
                );
            },
            setResult(result) {
                this.search = result.sName;
                this.isOpen = false;
                this.isLoading = true;
                this.dataReady = false;

                this.jobTypeInfo = [];               
                this.jobTypeOfflineMsg = null;
                this.sopResponseData = {};
                this.sopErrorMsg = null;
                this.isOnline = true;

                Promise.all([
                    this.getSopInfo(result.sId),
                ])
                .then()
                .catch(() => {
                    this.sopErrorMsg = "We're experiencing technical difficulties.  Please try again later.";
                })
                .finally(() => ((this.isLoading = false), (this.dataReady = true)));
            },
            getSopInfo(jobType) {
                var relevantSops = [];
                var mandatorySops = [];
                var notMandatorySops = [];

                // identity sops for job type
                this.sopJobTypeList.forEach((sopJobType) => {
                    if (sopJobType.jobTypeId == jobType) {
                        relevantSops.push(sopJobType);
                    }
                });

                // build mandatory and not mandatory lists
                this.sopList.forEach((sop) => {
                    relevantSops.forEach((sopJobType) => {
                        if (sop.sopId == sopJobType.sopId) {
                            if (sopJobType.sopIsMandatory == true){
                                mandatorySops.push(sop);
                            }
                            else {
                                notMandatorySops.push(sop);
                            }
                        }
                    });
                });

                this.sopResponseData = { 'mandatorySops' : mandatorySops, 'notMandatorySops' : notMandatorySops };
            },
            onArrowDown() {
                if (this.arrowCounter < this.results.length) {
                    this.arrowCounter = this.arrowCounter + 1;
                }
            },
            onArrowUp() {
                if (this.arrowCounter > 0) {
                    this.arrowCounter = this.arrowCounter - 1;
                }
            },
            onEnter() {
                this.setResult(this.results[this.arrowCounter]);
                this.arrowCounter = -1;
            },
            updateArrow(iVal) {
                this.arrowCounter = iVal;
            },
            closeAutocomplete(evt) {
                if (!this.$el.contains(evt.target)) {
                    this.isOpen = false;
                    this.arrowCounter = -1;
                }
            },
            clearData() {
                this.search = "";
                this.dataReady = false;            
                this.jobTypeOfflineMsg = null;
                this.sopResponseData = {};
                this.sopErrorMsg = null;
            },
            refreshAllData() {
                this.errored = false;
                this.errorMessage = "";
                this.isLoading = true;
                Promise.all([
                    this.fetchJobTypeInfo(),
                    this.fetchAllSOPList(),
                    this.fetchAllSOPJobTypeList()
                ]).finally(() => (this.isLoading = false));
            },
        },
    };
</script>

<style>
    .autocomplete {
        position: relative;
    }

    .autocomplete-results {
        padding: 0;
        margin: 0;
        border: 1px solid #eeeeee;
        overflow: auto;
    }

    .autocomplete-result {
        list-style: none;
        text-align: left;
        padding: 4px 2px;
        cursor: pointer;
    }

    .autocomplete-result.is-active,
    .autocomplete-result:hover {
        background-color: rgb(247, 151, 41);
        color: white;
    }

    .collapsed > .when-opened,
    :not(.collapsed) > .when-closed {
        display: none;
    }
</style>