export const SOP = {
    TITLE: "Standard Operating Procedures",
    DESCRIPTION: "<p>One of our core 4 responsibilities is to continually \"improve processes and procedures\" so if you have a suggestion to improve SOPs assigned to a given job type, please submit a <a href=\"https://nisource.sharepoint.com/sites/Mysource_ngd/Standards/SitePages/default.aspx\" target=\"_blank\">SEAS request</a> to help us continuously improve this information.</p>",
    REQUIRED: {
        TITLE: "Required",
        DESCRIPTION:"<p>Required SOPs are expected to be used each time for this job type. Please select and complete only the SOPs for the task that you are performing which may mean selecting between multiple items that are listed (e.g., multiple joining methods).</p>",
    },
    CONDITIONAL: {
        TITLE: "Conditional",
        DESCRIPTION:"<p>Conditional SOPs are expected to be used and are required when the conditions of the job calls for this high consequence task. For example, \"SOP 1100.010-2 Locate and Mark: Resolving Problem Locates on Facilities\" should be selected and completed every time that a locate cannot be completed successfully.</p>",
    }
}

export const OQ = {
    TITLE: "Operator Qualifications",
    DESCRIPTION: ""
}