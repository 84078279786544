<template>
    <div class="vld-parent">
        <loading :active.sync="isLoading"
                 :loader="loaderType"
                 :color="loaderColor"
                 :background-color="bgroundColor"
                 :is-full-page="fullPage"></loading>
        <b-container>
            <!-- Qualification Date pop up -->
            <b-row class="pb-3 mb-3 border-bottom" v-if="asOfDate !== null && asOfDate !== ''">
                <b-col>
                    <b-alert :variant="dataIsStale ? 'warning' : 'success'" show>
                        <b-row align-v="center">
                            <b-col cols="9" align-self="center">
                                <span style="font-size: 110%">Qualification Data loaded on <b>{{ asOfDate }}</b></span>
                            </b-col>
                            <b-col cols="3" class="clearfix">
                                <b-button @click="refreshData" variant="light" class="float-right">
                                    <b-icon icon="arrow-repeat" variant="success" font-scale="1.3" rotate="45" class="text-center"></b-icon>Reload
                                </b-button>
                            </b-col>
                        </b-row>
                    </b-alert>
                </b-col>
            </b-row>

            <!-- Page View Change dropdown -->
            <b-row>
                <b-col sm="12" lg="6">
                    <b-form-select v-model="dropdownFilterSelected" :options="dropdownFilterOptions">
                        <b-form-select-option v-for="series in distinctSeries" :key="series.ID" v-bind:value="series.ID">
                            {{ series.Name }}
                        </b-form-select-option>
                    </b-form-select>
                </b-col>
                <b-col sm="12" lg="6" class="text-center mt-2">
                    <b-row>
                        <b-col sm class="p-0">
                            <b-icon icon="check-circle" font-scale="1.25" variant="success" bold class="align-middle"></b-icon> Qualified
                        </b-col>
                        <b-col sm="5" class="p-0">
                            <b-icon icon="exclamation-triangle" font-scale="1.25" variant="warning" bold class="align-middle"></b-icon> Due Within 90 Days
                        </b-col>   
                        <b-col sm class="p-0">
                            <b-icon icon="x-circle" font-scale="1.25" variant="danger" bold class="align-middle"></b-icon> Not Qualified
                        </b-col>
                    </b-row>
                </b-col>
            </b-row>

            <!-- Start Data Tables -->
            <!-- All and OQ Common Portion -->
            <div v-if="dropdownFilterSelected !== 'Roles' && dropdownFilterSelected !== 'JobType'">
                <dataTable :certInfo="certInfo" :dropdownFilterSelectedValue="dropdownFilterSelected" uniqueTableId="tableId_0" />
            </div>

            <!-- Role section -->
            <div v-else-if="dropdownFilterSelected == 'Roles'" class="my-2" role="tablist">
                <b-card v-for="item in groupedByRoles" :key="item.roleId" no-body class="mb-1">
                    <b-card-header header-tag="header" class="p-1" role="tab">
                        <b-button block v-b-toggle="'accordion-' + item.roleId" variant="medium" class="d-flex justify-content-between align-items-center">
                            <span>{{ item.roleName }}</span>
                            <span class="text-right when-closed">
                                <b-icon icon="plus-circle-fill" font-scale="2" variant="primary" bold></b-icon>
                            </span>
                            <span class="text-right when-opened">
                                <b-icon icon="dash-circle-fill" font-scale="2" variant="dark"></b-icon>
                            </span>
                        </b-button>
                    </b-card-header>
                    <b-collapse :id="'accordion-' + item.roleId" accordion="my-accordion" role="tabpanel">
                        <b-card-body>
                            <dataTable :certInfo="item.certTasks" :dropdownFilterSelectedValue="dropdownFilterSelected" :uniqueTableId="item.roleId" />
                        </b-card-body>
                    </b-collapse>
                </b-card>
            </div>

            <!-- Job Type SOP Portion -->
            <div v-else class="my-2 autocomplete" role="tablist">
                <!-- <v-card> -->
                <b-card>
                    <b-form-group label-cols-sm="12"
                                  label-align-sm="left"
                                  label-size="sm"
                                  label-for="filterInput"
                                  class="mb-0">
                        <b-input-group size="sm">
                            <b-form-input v-model="search"
                                          type="search"
                                          id="filterInput"
                                          placeholder="Start Typing to Search"
                                          @input="onChange"
                                          @keydown.down="onArrowDown"
                                          @keydown.up="onArrowUp"
                                          @keydown.enter="onEnter" />

                            <b-input-group-append>
                                <b-button :disabled="!search" @click="clearData">Clear</b-button>
                            </b-input-group-append>
                        </b-input-group>
                    </b-form-group>

                    <ul v-show="isOpen" class="autocomplete-results">
                        <li v-for="(result, i) in results"
                            :key="i"
                            @click="setResult(result)"
                            class="autocomplete-result"
                            :class="{ 'is-active': i === arrowCounter }"
                            v-on:mouseover="updateArrow(i)">
                            {{ result.sName }}
                        </li>
                    </ul>
                    <sopComponent :title="SOP_TITLE"
                                  :description="SOP_DESCRIPTION"
                                  :sopDataObject="sopResponseData"
                                  :errorMessage="sopErrorMsg"
                                  :jobName="search"
                                  :employeeState="employeeState"
                                  v-show="dataReady" />
                    <jobTypeTable :title="OQ_TITLE"
                                  :description="OQ_DESCRIPTION"
                                  :jobTypeInfo="jobTypeInfo"
                                  :errorMessage="jobTypeOfflineMsg"
                                  :dropdownFilterSelectedValue="dropdownFilterSelected"
                                  :isOnline="isOnline"
                                  uniqueTableId="jobs_0"
                                  v-show="dataReady" />
                </b-card>
            </div>
            <!-- End Data Tables -->
        </b-container>
    </div>
</template>


<script>
    import dataTable from "../components/DataTable";
    import jobTypeTable from "../components/JobTypeDataTable";
    import sopComponent from "../components/SopComponent";
    import ApiService from "@/lib/api-service";
    import Loading from "vue-loading-overlay";
    import "vue-loading-overlay/dist/vue-loading.css";
    import { OQ, SOP } from "../utils/locale";

    export default {
        name: "CertData",
        props: {
            groupedByTasks: Array,
            groupedByRoles: Array,
            asOfDate: String,
            refreshCertData: Function,
            employeeState: String,
        },
        components: {
            dataTable,
            jobTypeTable,
            sopComponent,
            Loading,
        },
        created() {
            this.SOP_TITLE = SOP.TITLE;
            this.SOP_DESCRIPTION = SOP.DESCRIPTION;
            this.OQ_TITLE = OQ.TITLE;
            this.OQ_DESCRIPTION = OQ.DESCRIPTION;
        },
        data() {
            return {
                certInfo: this.groupedByTasks,
                distinctSeries: this.getDistinctSeries(),
                dropdownFilterSelected: "All",
                dropdownFilterOptions: [
                    { value: "All", text: "All" },
                    { value: "Roles", text: "Roles" },
                    { value: "JobType", text: "Job Type (OQs\\SOPs)" },
                ],
                search: "",
                results: [],
                sItems: [],
                isOpen: false,
                arrowCounter: -1,
                dataReady: false,
                jobTypeInfo: [],
                jobTypeOfflineMsg: "",
                isLoading: false,
                fullPage: true,
                loaderType: "bars",
                loaderColor: "rgb(247, 151, 41)",
                bgroundColor: "rgb(0,0,0)",
                sopResponseData: {},
                sopErrorMsg: "",
                jobTypeList: [],
                sopList: [],
                sopJobTypeList: [],
                jobTypeMappings: [],
                isOnline: true,
            };
        },
        computed: {
            dataIsStale: function () {
                if (!this.asOfDate) return true;
                var currentDate = new Date();
                var asOfDate = new Date(this.asOfDate);
                return (
                    currentDate >= asOfDate &&
                    !(
                        Math.floor(
                            (new Date() - new Date(this.asOfDate)) / (1000 * 60 * 60)
                        ) === 0
                    )
                ); //check to see if asOfDate is an hour old or more
            },
        },
        mounted() {
            Promise.all([this.fetchJobTypeList(), this.fetchAllSOPList(), this.fetchAllSOPJobTypeList(), this.fetchJobTypeMappings()])
                .then()
                .finally(() => (this.loading = false));
        },
        updated() { },
        destroyed() {
            document.removeEventListener("click", this.closeAutocomplete);
        },
        methods: {
            getDistinctSeries() {
                var lookup = {};
                var items = this.groupedByTasks;
                var result = [];

                items.forEach(function (value) {
                    var SeriesName = value.seriesName;
                    var SeriesId = value.seriesId;

                    if (!(SeriesName in lookup)) {
                        lookup[SeriesName] = 1;
                        lookup[SeriesId] = 1;
                        result.push({ ID: SeriesId.toString(), Name: SeriesName });
                    }
                });
                return result;
            },
            consoleLog(toLog) {
                console.log(toLog);
            },
            onChange() {
                if (this.search == "") {
                    this.isOpen = false;
                } else {
                    this.isOpen = true;
                    this.filterResults();
                }
            },
            filterResults() {
                const searchCon = this.search.toLowerCase().trim();
                if (!searchCon) {
                    this.results = this.sItems;
                }
                //this.results = this.sItems.filter(item => item.toLowerCase().indexOf(this.search.toLowerCase()) > -1);
                this.results = this.sItems.filter(
                    (s) => s.sName.toLowerCase().indexOf(searchCon) > -1
                );
            },
            setResult(result) {
                this.search = result.sName;
                this.isOpen = false;
                this.isLoading = true;
                this.dataReady = false;

                this.jobTypeInfo = [];               
                this.jobTypeOfflineMsg = null;
                this.sopResponseData = {};
                this.sopErrorMsg = null;
                this.isOnline = true;

                Promise.all([
                    this.getJobTypeInfo(result.sId),
                    this.getSopInfo(result.sId),
                ])
                    .then()
                    .catch(() => {
                        this.jobTypeOfflineMsg = "We're experiencing technical difficulties.  Please try again later.";
                        this.sopErrorMsg = "We're experiencing technical difficulties.  Please try again later.";
                    })
                    .finally(() => ((this.isLoading = false), (this.dataReady = true)));
            },
            fetchJobTypeList() {
                return ApiService.fetchJobTypeList()
                    .then((response) => {
                        if (typeof response === "string") {
                            this.errored = true;
                            this.errorMessage = response;
                        } else {
                            this.jobTypeList = response;

                            var jtItems = this.jobTypeList;
                            var tempItems = [];

                            jtItems.forEach(function (value) {
                                tempItems.push({
                                    sId: value.id,
                                    sName: value.jobTypeCode + " - " + value.jobTypeDesc,
                                });
                            });

                            document.addEventListener("click", this.closeAutocomplete);
                            this.sItems = tempItems;
                        }
                    });
            },
            fetchAllSOPList() {
                return ApiService.fetchAllSOPList()
                    .then((response) => {
                        if (typeof response === "string") {
                            this.errored = true;
                            this.errorMessage = response;
                        } else {
                            this.sopList = response;
                        }
                    });
            },
            fetchAllSOPJobTypeList() {
                return ApiService.fetchAllSOPJobTypeList()
                    .then((response) => {
                        if (typeof response === "string") {
                            this.errored = true;
                            this.errorMessage = response;
                        } else {
                            this.sopJobTypeList = response;
                        }
                    });
            },
            fetchJobTypeMappings() {
                return ApiService.fetchJobTypeMappings(
                    this.$route.params.UserId
                    )
                    .then((response) => {
                        if (typeof response === "string") {
                            this.errored = true;
                            this.errorMessage = response;
                        } else {
                            this.jobTypeMappings = response;
                        }
                    });
            },
            getJobTypeInfo(jobType) {
                var jobTypeTasks = [];
                var relevantJobTypeMappings = [];

                // loop through mappings, find all tasks for the job type
                this.jobTypeMappings.forEach((mapping) => {
                    if (mapping.jobTypeId == jobType) {
                        relevantJobTypeMappings.push(mapping);
                    }
                });

                // find OQ tasks for job type
                relevantJobTypeMappings.forEach((relMapping) => {
                    var found = false;
                    var i = 0;

                    do {
                        if (this.certInfo[i].taskCode == relMapping.taskCode) {
                            jobTypeTasks.push(this.certInfo[i]);
                            found = true;
                        }
                        
                        i = i + 1;
                    } while (found == false && i < this.certInfo.length);

                    // if not found in user's certs, add empty one to show Not Qualified
                    if (!found) {
                        jobTypeTasks.push({
                            dateKnowledgeTaken: null,
                            dateSkillCertExpired: null,
                            isTaskQualified: null,
                            knowledgeCertStatus: null,
                            seriesId: null,
                            seriesName: null,
                            skills: null,
                            source: null,
                            taskCode: relMapping.taskCode,
                            taskName: relMapping.taskName
                        });
                    }
                });
                
                this.jobTypeInfo = jobTypeTasks;
            },
            getSopInfo(jobType) {
                var relevantSops = [];
                var mandatorySops = [];
                var notMandatorySops = [];

                // identity sops for job type
                this.sopJobTypeList.forEach((sopJobType) => {
                    if (sopJobType.jobTypeId == jobType) {
                        relevantSops.push(sopJobType);
                    }
                });

                // build mandatory and not mandatory lists
                this.sopList.forEach((sop) => {
                    relevantSops.forEach((sopJobType) => {
                        if (sop.sopId == sopJobType.sopId) {
                            if (sopJobType.sopIsMandatory == true){
                                mandatorySops.push(sop);
                            }
                            else {
                                notMandatorySops.push(sop);
                            }
                        }
                    });
                });

                this.sopResponseData = { 'mandatorySops' : mandatorySops, 'notMandatorySops' : notMandatorySops };
            },
            onArrowDown() {
                if (this.arrowCounter < this.results.length) {
                    this.arrowCounter = this.arrowCounter + 1;
                }
            },
            onArrowUp() {
                if (this.arrowCounter > 0) {
                    this.arrowCounter = this.arrowCounter - 1;
                }
            },
            onEnter() {
                this.setResult(this.results[this.arrowCounter]);
                this.arrowCounter = -1;
            },
            updateArrow(iVal) {
                this.arrowCounter = iVal;
            },
            closeAutocomplete(evt) {
                if (!this.$el.contains(evt.target)) {
                    this.isOpen = false;
                    this.arrowCounter = -1;
                }
            },
            clearData() {
                this.search = "";
                this.dataReady = false;          
                this.jobTypeOfflineMsg = null;
                this.sopResponseData = {};
                this.sopErrorMsg = null;
            },
            refreshData() {
                this.refreshCertData();
                Promise.all([
                    this.fetchJobTypeInfo(),
                    this.fetchAllSOPList(),
                    this.fetchAllSOPJobTypeList(),
                    this.fetchJobTypeMappings()
                ]).finally(() => (this.isLoading = false));
            },
        },
    };
</script>

<style>
    .autocomplete {
        position: relative;
    }

    .autocomplete-results {
        padding: 0;
        margin: 0;
        border: 1px solid #eeeeee;
        overflow: auto;
    }

    .autocomplete-result {
        list-style: none;
        text-align: left;
        padding: 4px 2px;
        cursor: pointer;
    }

        .autocomplete-result.is-active,
        .autocomplete-result:hover {
            background-color: rgb(247, 151, 41);
            color: white;
        }

    .collapsed > .when-opened,
    :not(.collapsed) > .when-closed {
        display: none;
    }
</style>