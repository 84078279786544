<template>
  <div v-if="errorMessage">
    <b-row v-if="title">
      <b-col>
        <h3 class="pt-3">{{ title }}</h3>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-alert show variant="danger">
          <b>{{ errorMessage }}</b>
        </b-alert>
      </b-col>
    </b-row>
  </div>
  <div v-else>
    <b-row v-if="title">
      <b-col>
        <h3 class="mt-3 pt-3">{{ title }}</h3>
        <div v-if="description" v-html="description"></div>
      </b-col>
    </b-row>
    <template v-if="mandatorySops.length > 0 || notMandatorySops.length > 0">
      <!-- If in the Pilot Group -->
      <!-- The $pilotUsers is a globally accessible variable to the data in the PilotUsers.json file.  It's set in the main.js file. -->
      <template v-if="isInDevonWayLinksPilot()">
        <sopDataTablePilot 
          :title="SOP_REQUIRED_TITLE"
          :description="SOP_REQUIRED_DESCRIPTION" 
          :sopData="mandatorySops" 
          uniqueTableId="sops_mandatory" />
        <sopDataTablePilot 
          :title="SOP_CONDITIONAL_TITLE"
          :description="SOP_CONDITIONAL_DESCRIPTION"
          :sopData="notMandatorySops" 
          uniqueTableId="sops_conditional" />
      </template>
      <!-- If in the normal group -->
      <template v-else>
        <sopDataTable
          :title="SOP_REQUIRED_TITLE"
          :description="SOP_REQUIRED_DESCRIPTION"
          :sopData="mandatorySops"
          uniqueTableId="sops_mandatory"
        />
        <sopDataTable
          :title="SOP_CONDITIONAL_TITLE"
          :description="SOP_CONDITIONAL_DESCRIPTION"
          :sopData="notMandatorySops"
          uniqueTableId="sops_conditional"
        />
      </template>
      
    </template>
    <template v-else>
      <div>
        <b-row>
          <b-col>
            <b-alert show variant="danger">
              <b>No SOPs required by job type {{ jobName }}</b>
            </b-alert>
          </b-col>
        </b-row>
      </div>
    </template>
  </div>
</template>

<script>
import sopDataTable from "../components/SopDataTable";
import { SOP } from "../utils/locale";
import sopDataTablePilot from "../components/SopDataTablePilot";

export default {
  name: "SopComponent",
  props: {
    jobName: String,
    errorMessage: String,
    sopDataObject: Object,
    title: String,
    description: String,
    employeeState: String,
  },
  components: {
    sopDataTable,
   sopDataTablePilot,
  },
  computed: {
    mandatorySops() {
      if (this.sopDataObject.mandatorySops) {
        return this.sopDataObject.mandatorySops;
      }

      return [];
    },
    notMandatorySops() {
      if (this.sopDataObject.notMandatorySops) {
        return this.sopDataObject.notMandatorySops;
      }

      return [];
    },
  },
  created() {
    this.SOP_REQUIRED_TITLE = SOP.REQUIRED.TITLE;
    this.SOP_REQUIRED_DESCRIPTION = SOP.REQUIRED.DESCRIPTION;
    this.SOP_CONDITIONAL_TITLE = SOP.CONDITIONAL.TITLE;
    this.SOP_CONDITIONAL_DESCRIPTION = SOP.CONDITIONAL.DESCRIPTION;
  },
  mounted() {},
  updated() {},
  methods: {
    consoleLog(toLog) {
      console.log(toLog);
    },
    isInDevonWayLinksPilot() {
      // only check this is a user id is provided (not for contractor page)
      if(this.$route.params.UserId) {
        // state overrides users
        if (this.$pilotUsers.PilotDevonWayLinks.States.includes(this.employeeState)){
          return true;
        }
        else {
          // have to check each user as uppercase
          var index = -1;
          index = this.$pilotUsers.PilotDevonWayLinks.Users.findIndex(element => {
            return element.toUpperCase() === this.$route.params.UserId.toUpperCase();
          });

          return (index > -1);
        }
      }
    }
  },
};
</script>